import React from 'react'
import styled from '@emotion/styled'
import {
  breakpoints
} from 'utils/styles'

const Footer = styled('footer')`
	padding: 100px 0 200px 0;	
	text-align:center;
	font-family:'Avenir Next';
	h5 {
		font-size: 2rem;
		color: #adb5bd
	}
	h3 {
		font-size: 3.5rem;
		letter-spacing: -3px;
		@media (max-width: ${breakpoints.tablet}px) {
	    	font-size: 2rem;
	    	letter-spacing: -1px;
	    }
	     @media (max-width: ${breakpoints.mobile}px) {
	      font-size: 1.7rem;
	    }
	}
	a {
		text-decoration:none;
		color: inherit;
	}

`
export default () => (
	<Footer>
		<h5>Say Hi</h5>
		<a href="mailto:chris@impromptu.dev"><h3><span style={{color:'rgb(252, 92, 148)'}}>chris</span>@impromptu.dev</h3></a>
	</Footer>
)