import React from 'react'
import { Link } from 'gatsby'

import styles from './Nav.module.scss'
import Logo from '../Brand'

export default (props) => (
	<nav>
		<ul className={styles.nav}>
			<li><Link to="/"><Logo height="40px"/></Link></li>
			<li><a href="mailto:chris@impromptu.dev">contact</a></li>
		</ul>
	</nav>
)