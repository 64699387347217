import React from "react"
import { keyframes } from '@emotion/core'
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'

import Footer from 'components/Footer'
import Nav from 'components/Nav'
import "./layout.css"

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`

const ViewPort = styled(`div`)`
  width: 100vw;
  max-width: 100vw;
  position: relative;
  animation: ${fadeIn} 0.2s ease forwards;
`
const Layout = ({ children }) => {
  return (
    <ViewPort>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1080,
          position: 'relative',
          overflow: 'hidden',
          padding: `0px 1.0875rem 1rem`,
          paddingTop: 0,
        }}
      >
        <Nav />
        <main style={{paddingTop:100}}>{children}</main>
        <Footer />
      </div>
    </ViewPort>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
